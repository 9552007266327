import { http } from '@/http/axios.js';

// 详情
export function getInfoAPI(params) {
  return http({
    url: '/capital/info',
    method: 'get',
    params
  })
}

export function editAprAPI(data) {
  return http({
    url: "/capital/apr/edit",
    method: "post",
    data,
  });
}
