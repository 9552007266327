<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="content">
      <div class="apr-info">
        <span>年化收益率：{{ profileData.capitalApr }} %</span>
        <span class="el-icon-edit" @click="editCapitalApr"></span>
      </div>
    </div>

    <el-dialog title="修改" :visible.sync="dialogVisibleApr" width="450px" :show-close="true">
      <el-form ref="formApr" :model="aprFormData" label-width="120px">
        <el-form-item label="原年化收益率">{{ profileData.capitalApr }} %</el-form-item>
        <el-form-item label="新年化收益率">
          <el-input style="width: 200px" v-model="aprFormData.capitalApr" size="small" placeholder="请输入6~24之间的整数"></el-input> %
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogVisibleApr = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveCapitalApr" size="small">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin';
import { getInfoAPI, editAprAPI } from './api';
import SelectDict from '@/views/components/select-dict.vue';

export default {
  name: 'CapitalProfile',
  mixins: [authBtnMixin],
  components: { SelectDict },
  data() {
    return {
      profileData: {
        capitalName: '',
        capitalMobile: '',
        capitalApr: '',
      },
      dialogVisibleApr: false,
      aprFormData: {
        capitalApr: '',
      },
    }
  },

  mounted() {
    this.getInfo();
  },

  methods: {
    async getInfo() {
      this.profileData = await getInfoAPI();
    },
    editCapitalApr() {
      this.dialogVisibleApr = true;
    },
    async saveCapitalApr() {
      await this.$refs.formApr.validate();
      await editAprAPI(this.aprFormData);
      await this.getInfo();
      this.dialogVisibleApr = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .content {
    font-size: 15px;
    font-weight: 600;
    .apr-info {
      margin-left: 20px;
    }
  }
  .el-icon-edit {
    cursor: pointer;
    margin-left: 10px;
    color: #1483ea;
  }
  .el-dialog {
    .el-dialog__body {
      padding-top: 10px;
    }
  }
  .el-form {
    .el-form-item {
      margin-right: 25px;
      margin-bottom: 0px;
    }
  }
}
</style>
